<template>
    <section class="product-page text-center">
        <v-img class="d-sm-none stripe" width="100%" src="~@/assets/home/SHU-runner2.jpg"/>
        <v-container>
            <div class="product-page__card pt-8 pt-sm-12 mb-16">

                <v-row class="flex-column flex-sm-row">
                    <v-col>
                        <div class="product-page__main mr-sm-16">
                            <div :class="item.IS_NEW ? 'product__main-image--before' : ''"
                                 class="product-page__image mb-11">
                                <!--<div v-if="item.IS_NEW" class="product-page__badge">
                                    <span>{{ texts.PRODUCT_NEBIE }}</span>
                                </div>-->

                                <div>
                                    <img v-if="imageUrl" :src="imageUrl" :alt="item.NAME"
                                         key="i1" width="100%" class="d-block mb-2">
                                </div>

                                <ul v-if="offer && offer.PHOTOS && offer.PHOTOS.length > 1"
                                    class="product-page__items d-flex justify-center">
                                    <li v-for="(photoUrl, index) of offer.PHOTOS" :key="index"
                                        class="product-page__item">
                                        <button @click="onOfferPhotoClick(index)">
                                            <img :src="photoUrl"
                                                 :alt="offer.NAME" width="100%">
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="product-page__inner text-left">
                            <h3 class="mb-5 d-sm-none">{{ item.NAME }}</h3>

                            <h2 class="mb-3 d-none d-sm-block">{{ item.NAME }}</h2>
                            <div class="product-page__about text-left">
                                <div class="product-page__about-main size-1 color-black">
                                    <p class="mb-15 mb-sm-8" v-html="detailText">
                                    </p>
                                </div>
                                <div v-if="item.offers && item.offers.length">
                                    <div class="product__colors ma-auto mb-2 d-inline-block">
                                        <div class="d-flex align-center justify-center flex-wrap">
                                            <button v-for="(offer, index) of item.offers" :key="index"
                                                    :title="offer.NAME"
                                                    @click="onOfferClick(offer, index)"
                                                    class="product__color"
                                            >
                                                <div :style="hexStyle(offer)"/>
                                            </button>
                                        </div>
                                    </div>
                                    <p class="product-page__color-name mb-11 size-1 color-gray-3">{{ offer.COLOR }}</p>
                                </div>
                                <div v-if="offer.WEIGHT" class="size-1 color-black mb-1">
                                    {{ offer.WEIGHT }}
                                </div>
                                <div class="size-1">
                                    <span class="d-inline-block mb-2">{{ texts.PRODUCT_INGREDIENTS }}</span>
                                    <p class="color-gray-3">
                                        {{ composition.text }}
                                    </p>
                                </div>

                                <!--                <p>{{ usage.text }}</p>
                                                <router-link :to="{name: 'Shops'}">
                                                  {{ texts.PRODUCT_BUY }}
                                                </router-link>-->
                            </div>


                        </div>
                    </v-col>
                </v-row>

            </div>

            <section v-if="relatedProducts && relatedProducts.length"
                     class="product-page__recomendations text-center pt-8">
                <h2 class="mb-8">{{ texts.PRODUCT_RECOMMEND }}</h2>

                <v-row class="d-flex justify-sm-center mb-8">
                    <v-col v-for="(item, index) of displayedProducts" :key="index" cols="12" sm="3" xl="3">
                        <product :item="item"/>
                    </v-col>
                </v-row>


                <!--<btn-blue v-if="relatedProducts.length > maxItems && !withAllItems" @click="withAllItems = true"
                          class="d-none d-xl-inline-block">Еще
                </btn-blue>-->
            </section>
        </v-container>

        <!--      <other-products />-->

        <!--<product-page-vegan/>

        <product-page-inspiration/>-->
        <insta-shu v-if="languageCode !== 'en'"/>

    </section>
</template>


<script>
import Product from "../components/product";
import MainBanner from "./main-banner";
import Swiper, {Pagination} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import BtnBlue from "../components/btn-blue";
import InstaShu from "./insta-shu";
import OtherProducts from "@/components/other-products";
import ProductPageInspiration from "@/views/product-page/product-page-inspiration";
import ProductPageVegan from "@/views/product-page/product-page-vegan";

const MAX_ITEMS = 4;

export default {
    name: 'ProductPage',
    components: {ProductPageVegan, ProductPageInspiration, OtherProducts, InstaShu, BtnBlue, MainBanner, Product},
    data() {
        return {
            currentSlide: 1,
            selectedOfferIndex: 0,
            selectedPhotoIndex: 0,
            withAllItems: true
        }
    },
    computed: {
        languageCode() {
            return this.$store.state.common.languageCode;
        },
        thirdBanner() {
            if (!this.$store.state.page?.ProductItemController?.banners) return null;
            if (this.$store.state.page?.ProductItemController?.banners.length < 3) return null;
            return this.$store.state.page?.ProductItemController?.banners[2];
        },
        page() {
            return this.$store.state.page.ProductItemController;
        },
        item() {
            return this.page?.item;
        },
        detailText() {
            return this.page?.item?.DETAIL_TEXT;
        },
        formula() {
            let obj = this.item?.FORMULA;
            return {
                text: obj.TEXT,
                type: obj.TYPE,
            };
        },
        usage() {
            let obj = this.item?.USAGE;
            return {
                text: obj.TEXT,
                type: obj.TYPE,
            };
        },
        weight() {
            //if (!this.item?.offers?.length) return;
            //const weight = this.item.offers.WEIGHT;
            //return weight;

            if (!this.item?.offers?.length) return;
            const offer = this.item.offers[this.selectedOfferIndex];
            if (!offer.WEIGHT?.length) return;
            const weight = offer.WEIGHT;
            return weight;
        },
        composition() {
            let obj = this.item?.COMPOSITION;
            return {
                text: obj.TEXT,
                type: obj.TYPE,
            };
        },
        maxItems() {
            return MAX_ITEMS;
        },
        displayedProducts() {
            return this.withAllItems ? this.relatedProducts : this.relatedProducts?.slice(0, this.maxItems);
        },
        relatedProducts() {
            if (!this.item?.linkedProducts?.length) return;
            return this.item?.linkedProducts;
        },
        imageUrl() {
            const offer = this.offer;
            if (!offer?.PHOTOS?.length) return;
            //const i = offer.PHOTOS.length >= 2 ? 1 : 0;
            const i = this.selectedPhotoIndex < offer.PHOTOS.length ? this.selectedPhotoIndex : 0;
            return offer.PHOTOS[i];
        },

        offer() {
            if (!this.item?.offers?.length) return;
            return this.item.offers[this.selectedOfferIndex];
        },

        texts() {
            return this.$store.state.page?.ProductItemController?.texts;
        }

    },
    methods: {
        onSlideChange() {
            this.currentSlide = (this.$refs.swiper?.$swiper?.activeIndex || 0) + 1;
        },
        slideNext() {
            this.$refs.swiper?.$swiper?.slideNext(500);
        },
        slidePrev() {
            this.$refs.swiper?.$swiper?.slidePrev(500);
        },
        hexStyle(offer) {
            return {
                "background-color": "#" + offer.COLOR_HEX
            };
        },
        onOfferClick(offer, index) {
            this.selectedOfferIndex = index;
            this.selectedPhotoIndex = 0;
        },

        onOfferPhotoClick(index) {
            this.selectedPhotoIndex = index;
        },
    },

    mounted() {

        setTimeout(() => {
            this.$store.state.swipers.ProductPage = this.$refs.swiper?.$swiper;
        }, 300);

        setTimeout(() => {
            const swiper = new Swiper('#product-page-swiper', {
                freeMode: true,
                slidesPerView: 1,
                modules: [Pagination],
                spaceBetween: 10,
                loop: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    960: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                }
            });
        }, 50);
    }
}
</script>


<style lang="scss">
.product-page {
    padding-top: 60px;
    @include up($sm) {
        padding-top: 100px;
    }

    &__recomendations {
        & .product__color {
            width: 31px;
            height: 31px;
        }
    }

    &__image {
        //max-width: 296px;
        position: relative;

        &--before {
            &:before {
                position: absolute;
                content: "";
                background-image: url("~@/assets/badge.svg");
                width: 93.34px;
                height: 93.34px;
                left: 0;
                top: 0;
            }
        }
    }

    &__inner {
        & .v-image {
            position: absolute;
            left: 0;
        }
        @include up($sm) {
            padding-top: 100px;
        }

        h2 {
            &:before {
                transform: unset;
                left: 0;
            }
        }
    }

    &__about {
        &-main {
            border-bottom: 1px solid #323234;
            margin-top: 100px;
            @include up($sm) {
                margin-top: 0;
            }
        }

        .product {
            &__colors {
                background-color: unset;
                box-shadow: unset;
                padding: 0;
                padding-top: 20px;
            }

            &__color {
                & div {
                    width: 38px;
                    height: 38px;
                }
            }
        }
    }

    &__badge {
        position: absolute;
        transform: rotate(315deg);
        top: 25px;
        left: 5px;
        text-transform: uppercase;
    }

    &__item {
        width: 84px;
        @include up($sm) {
            width: 172px;
        }

        &:not(:last-child) {
            margin-right: 7px;
        }

        button {
            height: 100px;
            @include up($sm) {
                height: 200px;
            }

            img {
                height: 100%;
            }
        }
    }

    &__colors {
        width: 208px;
        height: 64px;
        background-color: $additional150;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.06);
        border-radius: 1000px;
        margin: auto;
        @include up($sm) {
            margin: 0;
        }
    }

    & details {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 32px;

            &::after {
                position: absolute;
                content: "";
                width: 100%;
                opacity: 0.5;
                border: 1px solid rgba(0, 0, 0, 0.16);
                bottom: -16px;
                left: 0;
            }
        }

        p {
            margin-top: 16px;
            @include up($sm) {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }

    & summary {
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        list-style: none;
        position: relative;
        @include up($sm) {
            font-size: 24px;
            line-height: 34px;
        }

        a {
            color: $black500 !important;
            margin-left: -6px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 2px;
            background-color: #B09D8A;
            border-radius: 999px;
            top: 13px;
            right: 0;
        }

        &::before {
            content: "";
            position: absolute;
            width: 2px;
            height: 12px;
            background-color: #B09D8A;
            border-radius: 999px;
            top: 8px;
            right: 5px;
        }
    }

    & details[open] summary {
        &::before {
            display: none;
        }
    }

    &__dots {
        span {
            width: 12px;
            height: 12px;
            background-color: $black500;
            opacity: 16%;
            transform: rotateZ(45deg);
            border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }

            &:active,
            &:hover {
                opacity: 100%;
                width: 16px;
                height: 16px;
            }
        }
    }

    .swiper-pagination {
        bottom: 0;

        &-bullet {
            width: 12px;
            height: 12px;
            background-color: $black500;
            opacity: 0.2;
            transform: rotateZ(45deg);
            border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        &-bullet-active {
            width: 16px;
            height: 16px;
            background: $black500;
            opacity: 1;
        }
    }

    &-vegan {
        @include up($sm) {
            margin-bottom: 70px;
        }
    }

    .other-products {
        margin-bottom: 40px;
        @include up($sm) {
            margin-top: 100px;
            margin-bottom: 64px;
        }
    }

    .insta-shu {
        margin-top: 80px;
        padding-bottom: 80px;
    }
}
</style>